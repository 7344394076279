@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;600;700;800&display=swap');

:root {
  --primary-dark: #5651e5;
  --primary-light: #709dff;
}

@layer base {
  html {
    @apply scroll-smooth;
  }

  body {
    @apply font-[Poppins];
  }

  li {
    @apply p-4 text-sm;
  }

  button {
    @apply px-2 py-1 md:px-4 md:py-2 border bg-gradient-to-r from-[var(--primary-dark)] to-[var(--primary-light)] text-white rounded-full;

    /* Smaller text size for mobile screens */
    @media (max-width: 640px) {
      font-size: 0.7rem; /* Adjust the desired smaller text size */
    }
  }

  .submit-button {
    /* Apply your button styles here */
    @apply px-2 py-1 md:px-4 md:py-2 border bg-gradient-to-r from-[var(--primary-dark)] to-[var(--primary-light)] text-white rounded-full;
  }

  h2 {
    @apply text-xl font-bold;
  }
}
